@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'NimbusMono';
  src: url('./assets/fonts/NimbusMono-Regular.otf') format('opentype');
}

body {
  background-color: #fff;
  font-family: sans-serif;
}

.sidebar_color {
  background: #0e86d4;
}

.link_dash {
  background-color: #004a93;
}

.link_dash:hover {
  color: inherit !important;
  background-color: rgb(64, 92, 251);
}
.link_dash_act:hover {
  color: #fff !important;
  background-color: rgb(64, 58, 237);
}

.new_role_btn {
  background-color: #072948;
  border-radius: 4px;
  padding: 5px 20px;
  font-size: 15px;
  height: 40px;
  color: #fff;
}

.custom_design {
  font-family: Helvetica, sans-serif !important;
}
.text_color_new {
  color: #e22929;
}
.bg_color_new {
  background: #e22929;
}

.fade-background {
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.fade-background.fade-out {
  opacity: 0.9;
}

.pdf-border {
  border: 1px solid #000 !important;
  height: 50px !important;
}

